export class ConsoleService {
  constructor() {
    this.records = []
    this.handlers = []
  }

  get data() {
    return [...this.records]
  }

  get message() {
    return this.data.join("\n")
  }

  bind(handler) {
    this.handler = handler
  }

  format(message) {
    return message instanceof Object ? JSON.stringify(message, null, 2) : message
  }

  log(...data) {
    for (const message of data) {
      this.records.push(this.format(message))
    }

    this.handler && this.handler.call(null, this)

    console.log(...data)
  }

  clear() {
    this.records = []
    this.handler && this.handler.call(null, this)
  }
}
