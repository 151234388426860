/**
 * 
 * @param {*} value 
 * @returns 
 */
export const formatCurrency = value => parseFloat(value).toLocaleString("ru-RU")

/**
 * 
 * @param {*} value 
 * @returns 
 */
export const formatFloat = value => String(value).replace(".", ",")

/**
 * Трансляция с русского на английский
 * @param {String} word - слово которое нужно перевести
 * @returns {string}
 */
export const translit = (word) => {
    let converter = {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "e",
        ж: "zh",
        з: "z",
        и: "i",
        й: "y",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "sch",
        ь: "",
        ы: "y",
        ъ: "",
        э: "e",
        ю: "yu",
        я: "ya",
    };

    word = word.toLowerCase();

    let answer = "";
    for (let i = 0; i < word.length; ++i) {
        if (converter[word[i]] === undefined) {
            answer += word[i];
        } else {
            answer += converter[word[i]];
        }
    }

    answer = answer.replace(/[^-0-9a-z]/g, "-");
    answer = answer.replace(/-+/g, "-");
    answer = answer.replace(/^\\-|-$/g, "");

    return answer;
};

/**
* Форматирует числа с десятичными разрядами
* @param {String|Number} number - число
* @param {Number} decimals - количество десятичных разрядов после точки
* @param {String} decimalSeparator - разделитель десятичных разрядов (например, 25.22/25,22)
* @param {String} thousandsSeparator - разделитель тысячных разрядов (например, 29 200 200/29,200,200)
* @returns {String} - отформатированная строка
*/
export const formatNumber = (number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') => {
    // Форматирует число с группировкой тысячных разрядов
    if (isNaN(decimals = Math.abs(decimals))) {
        decimals = 2;
    }
    if (decimalSeparator === undefined) {
        decimalSeparator = ',';
    }
    if (thousandsSeparator === undefined) {
        thousandsSeparator = '.';
    }

    let integerPart = parseInt(number = (+number || 0).toFixed(decimals)).toString();
    let firstGroupLength = integerPart.length > 3 ? integerPart.length % 3 : 0;
    let minusSign = number.includes('-') ? '-' : '';

    let thousands = firstGroupLength ? integerPart.substr(0, firstGroupLength) + thousandsSeparator : '';
    let remainingGroups = integerPart.substr(firstGroupLength).replace(/(\d{3})(?=\d)/g, '$1' + thousandsSeparator);
    let decimalPart = decimals ? decimalSeparator + Math.abs(number - integerPart).toFixed(decimals).replace(/-/, 0).slice(2) : '';

    return minusSign + thousands + remainingGroups + decimalPart;
};

