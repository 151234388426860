/**
 * Сервис Telegram
 * https://core.telegram.org/bots/webapps
 */
export class TelegramService {
  constructor() {
    this.telegram = window.Telegram || null
  }

  async init() {
    if (this.telegram) {
      return this
    }

    return await new Promise((resolve, reject) => {
      const script = document.createElement("script")
      const timeoutId = setTimeout(() => reject(), 3000)

      script.setAttribute("src", "https://telegram.org/js/telegram-web-app.js")
      script.addEventListener("load", () => {
        this.telegram = window.Telegram || null
        // this.gameProxy = window.TelegramGameProxy || {}
        // this.receiveEvent = window.TelegramGameProxy_receiveEvent || 

        clearTimeout(timeoutId)
        resolve(this)
      })

      document.head.appendChild(script)
    })
  }
  
  get app() {
    return this.telegram.WebApp || {}
  }

  getUser() {
    const { user } = this.app.initDataUnsafe || {}
  
    return user || { id: 0 }
  }
}
