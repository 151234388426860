/**
 * Сервис аналитики
 */
// export class AnalyticsService {
//   /**
//    *
//    * @param {*} user
//    * @param {*} config
//    */
//   constructor(user, config) {
//     this.user = user || {}
//     this.config = config || {}
//     this.isReady = false
//   }
//
//   get gtmId() {
//     return this.config.gtmId || ""
//   }
//
//   get enabled() {
//     return !!this.gtmId
//   }
//
//   /**
//    *
//    * @returns
//    */
//   async init() {
//     if (!this.enabled || this.isReady) {
//       return this
//     }
//
//     return await new Promise((resolve, reject) => {
//       const firstScript = document.getElementsByTagName("script")[0]
//       const gtmScript = document.createElement("script")
//       const timeoutId = setTimeout(() => reject(), 3000)
//
//       window.dataLayer = window.dataLayer || []
//       window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
//
//       gtmScript.setAttribute("async", true)
//       gtmScript.setAttribute("src", `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`)
//       gtmScript.addEventListener("load", () => {
//         this.isReady = true
//
//         clearTimeout(timeoutId)
//         resolve(this)
//       })
//
//       firstScript.parentNode.insertBefore(gtmScript, firstScript)
//     })
//   }
//
//   /**
//    *
//    * @param {*} event
//    * @param {*} data
//    * @returns
//    */
//   push(event, data) {
//     event = String(event).toLowerCase()
//     data = data || {}
//
//     data.userId = this.user.id || 0
//     data.srcMedium = this.user.source_medium || "display"
//     data.srcCampaign = this.user.source_campaign || "general"
//
//     console.info("AnalyticsService:push", { event, ...data })
//
//     this.enabled && window.dataLayer.push({ ...data, event })
//   }
// }

export class AnalyticsService {
  constructor(userId, config) {
    this.config = config || {};
    this.gtmId = this.config.gtmId;
    this.userId = userId?.id || null;
    this.isReady = false;
    this.gtmNoscript = this.config.gtmNoscript

    window.dataLayer = window.dataLayer || [];
    this.dataLayer = window.dataLayer;
  }

  get enabled() {
    return !!this.gtmId;
  }

  async init() {
    if (!this.enabled || this.isReady) {
      return this;
    }

    return await new Promise((resolve, reject) => {
      const firstScript = document.getElementsByTagName("script")[0];
      const secondScript = document.createElement('noscript');
      secondScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      const gtmScript = document.createElement("script");
      const timeoutId = setTimeout(() => reject(), 3000);

      this.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

      gtmScript.setAttribute("async", "true");
      gtmScript.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`);
      gtmScript.addEventListener("load", () => {
        this.isReady = true;

        window.gtag = () => {
          return this.gtag.bind(arguments);
        };

        this.gtag("js", new Date());
        this.gtag("config", this.gtmId);
        this.gtag("set", "user_properties", { user_id: this.userId });

        clearTimeout(timeoutId);
        resolve(this);
      });

      firstScript.parentNode.insertBefore(gtmScript, firstScript);
      document.body.insertBefore(secondScript, document.body.firstChild);
    });
  }

  setUserId(userId) {
    this.userId = userId;
  }

  gtag(...args) {
    this.dataLayer.push(arguments);
  }

  /**
   * Отправить событие
   * @param {string} event
   * @param data
   * @returns
   */
  push(event, data) {
    data = data || {};
    data.userId = this.userId || 0;

    if (!this.enabled) {
      return;
    }

    this.gtag("event", event, data);

    if (event === "page_opened") {
      const { lang = "ru", title = 'WoW2U' } = data;

      this.gtag("page_view", {
        ...data,
        page_title: title,
        page_location: window.location.href,
      });
    }
  }
}