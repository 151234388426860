import Vue from "vue"
import Vuex from "vuex"
import {translit} from "@/helpers/format.helper";

Vue.use(Vuex)

export default ({config, service}) => {
    const THEME_LIGHT = "light"
    const THEME_DARK = "dark"

    const VIEW_FORM = "Form"
    const VIEW_CATALOG = "Catalog"
    const VIEW_DETAIL = "Detail"
    const VIEW_PROGRAM = "Program"
    const VIEW_FAVORITES = "Favorites"
    const VIEW_MAP = "Map"
    const VIEW_NOT_FOUND = "NotFound"
    const VIEW_TEST = "Test"

    const CITY_ID_MOSCOW = 1

    return new Vuex.Store({
        state: {
            user: {},
            isReady: false,
            isFetching: false,
            externalUrl: null,
            theme: THEME_LIGHT,
            view: VIEW_FORM,
            prevView: null,
            currentChunk: 0,
            totalChunks: 2,
            isLastChunk: false,
            apartments: [],
            favorites: [],
            selectedIndex: 0,
            openedApartment: null,
            selectedProgram: null,
            debugData: [],
            costRange: {
                min: 0,
                max: 0
            },
            cities: [],
            citiesQuery: [
                {
                    name: "Обнинск",
                    link: "obninsk"
                },
                {
                    name: "Ростов-на-Дону",
                    link: 'rostov-na-donu'
                },
                {
                    name: "Новороссийск",
                    link: "novorossiisk"
                },
                {
                    name: "Тюмень",
                    link: "tumen"
                },
                {
                    name: "Южно-Сахалинск",
                    link: "yuzhno-sakhalinsk"
                },
                {
                    name: "Ярославль",
                    link: "yaroslavl"
                },
                {
                    name: "Санкт-Петербург",
                    link: "spb"
                },
                {
                    name: "Москва и область",
                    link: null
                },
                {
                    name: "Нижний Новгород",
                    link: "nn"
                },
                {
                    name: "Казань",
                    link: "kazan"
                },
                {
                    name: "Владивосток",
                    link: "vladivostok"
                },
                {
                    name: "Екатеринбург",
                    link: "ekb"
                },
                {
                    name: "Благовещенск",
                    link: "blagoveshchensk"
                }
            ],
            filter: {
                cost: "",
                city: CITY_ID_MOSCOW,
            },
            viewport: {
                width: null,
                height: null
            },
            price: [0, 0],
            targetOptions: [
                {
                    id: 1,
                    name: 'Подбираю первое жильё'
                },
                {
                    id: 2,
                    name: 'Для семьи с детьми'
                },
                {
                    id: 3,
                    name: 'Ту, что вырастет в цене'
                },
            ],
            targetValue: parseInt(config.apartmentType) || 1
        },

        getters: {
            $config: () => config,
            $service: () => service,
            $console: () => service.console,
            $userService: () => service.user,
            $apiService: () => service.api,
            $analyticService: () => service.analytics,
            $tgService: () => service.tg,

            user: ({user}) => user,
            userId: ({}, {user}) => user.id,

            isReady: ({isReady}) => isReady,
            isDebug: ({}, {$config}) => $config.debug,
            isLightTheme: ({theme}) => theme === THEME_LIGHT,
            isDarkTheme: ({theme}) => theme === THEME_DARK,
            hasAnalytics: ({}, {$analyticService}) => !!$analyticService,
            debugData: ({debugData}) => debugData,

            hasHeader: ({}, {isCatalog, isDetail}) => isCatalog || isDetail,
            hasFooter: ({}, {isDebug, isForm, isTest}) => isDebug && (isForm || isTest),

            isForm: ({view}) => view === VIEW_FORM,
            isCatalog: ({view}) => view === VIEW_CATALOG,
            isDetail: ({view}) => view === VIEW_DETAIL,
            isFavorites: ({view}) => view === VIEW_FAVORITES,
            isMap: ({view}) => view === VIEW_MAP,
            isTest: ({view}, {isDebug}) => isDebug && view === VIEW_TEST,

            // history: ({ history }) => history,
            externalUrl: ({externalUrl}) => externalUrl,
            theme: ({theme}) => theme,
            view: ({view}) => view,
            prevView: ({prevView}) => prevView,
            filter: ({filter}) => filter,
            cost: ({filter}) => filter.cost || "",
            price: ({price}) => price || [],
            city: ({filter}) => filter.city || null,
            cityName: ({filter, cities}) => cities.find(item => item.id === filter.city)?.name,
            cities: ({cities}) => cities,
            costRange: ({costRange}) => costRange,
            // minCost: ({ costRange }) => Math.ceil(costRange.min / 1000) * 1000,
            // maxCost: ({ costRange }) => Math.floor(costRange.max / 1000) * 1000,
            minCost: ({costRange}) => costRange.min,
            maxCost: ({costRange}) => costRange.max,
            hapticFeedback: ({}, {$tgService}) => $tgService.app.HapticFeedback,
            backButton: ({}, {$tgService}) => $tgService.app.BackButton,
            backRoutesMap: () => {
                return {
                    [VIEW_CATALOG]: VIEW_FORM,
                    [VIEW_DETAIL]: VIEW_CATALOG,
                    [VIEW_FAVORITES]: VIEW_CATALOG,
                    [VIEW_MAP]: VIEW_CATALOG
                }
            },
            backRoute: ({}, {backRoutesMap, prevView, view}) => prevView || backRoutesMap[view] || VIEW_FORM,

            siteLink: ({targetValue, price, cities, filter}) => {
                const baseLink = "https://www.pik.ru/search"
                const settlementQuery = '&settlement=2.2026';
                const projectQuery = `?projects=1`;
                const priceToQuery = `&priceTo=${price[1]}`

                let apartmentType = null;
                if (targetValue === 1) {
                    apartmentType = '-1,1';
                } else if (targetValue === 2) {
                    apartmentType = '2,3';
                }

                let utmTypeQuery = null;
                if (parseInt(config.apartmentType) === 1) {
                    utmTypeQuery = "&utm_source=telegram&utm_medium=display&utm_campaign=general&utm_content=pik_podborki_first_bot"
                } else if (parseInt(config.apartmentType) === 2) {
                    utmTypeQuery = "&utm_source=telegram&utm_medium=display&utm_campaign=general&utm_content=pik_podborki_family_bot"
                } else if (parseInt(config.apartmentType) === 3) {
                    utmTypeQuery = "&utm_source=telegram&utm_medium=display&utm_campaign=general&utm_content=pik_podborki_invest_bot"
                }

                const apartmentTypeQuery = `&rooms=${apartmentType}`

                const {link: cityLink} = cities.find(item => item.id === filter.city) || {};

                return baseLink + (cityLink ? `/${cityLink}/` : "") + projectQuery + (apartmentType ? apartmentTypeQuery : settlementQuery) + priceToQuery + (config.apartmentType ? utmTypeQuery : "");
            },

            citiesQuery: ({citiesQuery}) => citiesQuery,

            currentChunk: ({currentChunk}) => currentChunk,
            totalChunks: ({totalChunks}) => totalChunks,
            getIsLastChunk: ({isLastChunk}) => isLastChunk,
            apartments: ({apartments}) => apartments,
            targetValue: ({targetValue}) => targetValue || "",
            targetOptions: ({targetOptions}) => targetOptions || [],
            currentTarget: ({
                                targetOptions,
                                targetValue
                            }) => targetOptions.find(item => item.id === targetValue) || null,
            openedApartment: ({openedApartment}) => openedApartment,
            openedApartmentId: ({}, {openedApartment}) => openedApartment.external_id || null,

            selectedIndex: ({selectedIndex}) => selectedIndex,
            selectedApartment: ({}, {apartments, selectedIndex}) => apartments[selectedIndex] || null,
            getSelectedProgram: ({}, {selectedProgram}) => selectedProgram,
            selectedApartmentId: ({}, {selectedApartment}) => selectedApartment.external_id || null,

            nextIndex: ({}, {selectedIndex}) => selectedIndex + 1,
            nextApartment: ({}, {apartments, nextIndex}) => apartments[nextIndex] || apartments[0] || null,

            nextFetchingIndex: ({}, {apartments}) => apartments.length - 10,

            favorites: ({favorites}) => favorites,
            favoriteIds: ({}, {favorites}) => favorites.map(favorite => favorite.id),
            favoritesNum: ({}, {favorites}) =>
                favorites.reduce((accum, item) => {
                    item.items.length && accum.push(...item.items)
                    return accum
                }, []).length,

            hasFetched: ({}, {apartments}) => !!apartments.length,
            canNextFetch: ({}, {selectedIndex, nextFetchingIndex}) => selectedIndex >= nextFetchingIndex,

            isFetching: ({isFetching}) => isFetching,
            isFirstFetching: ({}, {hasFetched, isFetching}) => !hasFetched && isFetching,
            isNextFetching: ({}, {hasFetched, isFetching}) => hasFetched && isFetching,
        },

        mutations: {
            setUser: (state, user) => {
                state.user = user
            },
            setReady: (state, status) => {
                state.isReady = status
            },
            setFetching: (state, status) => {
                state.isFetching = status
            },
            setExternalUrl: (state, url) => {
                state.externalUrl = url
            },
            setTheme: (state, theme) => {
                state.theme = theme
            },
            setView: (state, view) => {
                state.view = view
            },
            setPrevView: (state, view) => {
                state.prevView = view
            },
            setCurrentChunk: (state, chunk) => {
                state.currentChunk = chunk
            },
            setTotalChunks: (state, totalChunks) => {
                state.totalChunks = totalChunks
            },
            setIsLastChunk: (state, value) => {
                state.isLastChunk = value;
            },
            setFilter: (state, filter) => {
                state.filter = filter
            },
            setCostRange: (state, costRange) => {
                state.costRange = costRange
            },
            setPrice: (state, price) => {
                state.price = price
            },
            setCities: (state, cities) => {
                state.cities = cities
            },
            setTargetValue: (state, targetValue) => {
                state.targetValue = targetValue
            },
            setSelectedIndex: (state, index) => {
                state.selectedIndex = index
            },
            setApartments: (state, apartments) => {
                state.apartments = apartments
            },
            setSelectedProgram: (state, program) => {
                state.selectedProgram = program
            },
            setOpenedApartment: (state, apartment) => {
                state.openedApartment = apartment
            },
            setFavorites: (state, favorites) => {
                state.favorites = favorites
            },
            setDebugData: (state, data) => {
                state.debugData = data
            }
        },

        actions: {
            init: async ({getters, commit, dispatch}) => {
                const {$userService, $tgService, backButton} = getters

                backButton.onClick(() => dispatch("toBack"))
                $tgService.app.onEvent("themeChanged", () => commit("setTheme", $tgService.app.colorScheme))

                dispatch("initListeners")
                dispatch("assignThemeParams")
                dispatch("fetchFavorites")

                commit("setUser", $userService.getUser())
                commit("setTheme", $tgService.app.colorScheme)

                await dispatch("fetchCities")

                setTimeout(() => {
                    const preloader = document.querySelector(".preloader")

                    preloader && preloader.remove()

                    commit("setReady", true)
                }, 1000)
            },
            pushEvent: async ({getters, commit, dispatch}, {event, data}) => {
                const {user, $apiService, $analyticService} = getters

                data = data || {}
                data.userId = user.id

                await $apiService.pushEvent(event, data)
                $analyticService.push(event, data)

                console.info("Analytics::pushEvent", {event, data})
            },
            toRoute: ({getters, commit, dispatch}, nextView, isEnabledAnalytic = true) => {
                const {backButton, view} = getters

                commit("setPrevView", view)
                commit("setView", nextView)

                if (isEnabledAnalytic) {
                    dispatch("pushEvent", {event: "ROUTE_TO", data: {from: view, to: nextView}})
                }

                if (nextView === VIEW_FORM) {
                    backButton.hide()

                    dispatch("resetApartaments")
                } else if (!backButton.isVisible) {
                    backButton.show()
                }

                dispatch("resetThemeParams")
            },
            toLink({getters, commit, dispatch}, url) {
                // commit("setExternalUrl", url)

                getters.$tgService.app.openLink(url)
                dispatch("pushEvent", {event: "ROUTE_TO_LINK", data: {url}})

                // getters.$tgService.app.openTelegramLink(url)

                // const link = document.createElement("a")

                // link.setAttribute("href", url)
                // link.click()
            },
            toBack: ({getters, commit, dispatch}) => {
                if (getters.externalUrl) {
                    commit("setExternalUrl", null)
                } else {
                    const {view, prevView} = getters
                    dispatch("pushEvent", {event: "ROUTE_TO_BACK", data: {from: view, to: getters.backRoute}})
                    dispatch("toRoute", getters.backRoute, false)
                    commit("setPrevView", null)
                }
            },
            changeFilter: ({getters, commit}, params) => {
                commit("setFilter", {...getters.filter, ...params})
            },
            selectCity: ({dispatch, getters}, {cityId, withoutAnalytic = false}) => {
                dispatch("changeFilter", {city: cityId})
                dispatch("fetchCostRange")

                const {city, cityName} = getters
                const data = {
                    cityId: city || 0,
                    cityName: cityName || "Город N"
                }

                !withoutAnalytic && dispatch("pushEvent", {event: "select-city", data})
            },
            changeCost: ({dispatch}, cost) => {
                dispatch("changeFilter", {cost})
            },
            selectApartment: ({getters, commit, dispatch}, index) => {
                commit("setSelectedIndex", index)

                // if (getters.canNextFetch) {
                // 	dispatch("fetchApartments")
                // }
            },
            openApartment: ({commit}, apartment) => {
                commit("setOpenedApartment", apartment)
            },
            updateFavorite: async ({getters, dispatch}, apartment) => {
                getters.hapticFeedback.impactOccurred("medium")
                getters.hapticFeedback.notificationOccurred("success")
                await getters.$apiService.updateFavorite(apartment.id, apartment.entity_type)

                dispatch("fetchFavorites")
            },
            fetchFavorites: async ({getters, commit}) => {
                // const localFavorites = getters.$userService.favorites

                // if (localFavorites.length) {
                // 	const localFavoriteIds = localFavorites.map(localFavorite => localFavorite.id)

                // 	await getters.$apiService.importFavorite(localFavoriteIds)
                // 	getters.$userService.unsetFavorites()
                // }

                const favorites = await getters.$apiService.fetchFavorites()

                commit("setFavorites", favorites)
            },
            fetchCostRange: async ({getters, commit}) => {
                try {
                    const {targetValue} = getters;

                    const costRange = await getters.$apiService.fetchCostRange([getters.city], targetValue)
                    commit("setPrice", [costRange.min, costRange.max])
                    commit("setCostRange", costRange)
                } catch (error) {
                    console.log(error)
                }
            },
            fetchCities: async ({getters, commit, dispatch}) => {
                const {$apiService, targetValue, citiesQuery} = getters;
                const cities = await $apiService.fetchCities(targetValue)
                const cityId = cities[0] ? cities[0].id : null

                const normalizedCities = cities.map(item => {
                    const {name = null} = item;

                    const alias = translit(name);
                    const {link = null} = citiesQuery.find(citiesQueryItem => translit(citiesQueryItem.name) === alias);
                    return {
                        ...item,
                        link
                    }
                });


                commit("setCities", normalizedCities)

                cityId && dispatch("selectCity", {cityId, withoutAnalytic: true})
            },
            fetchApartments: async ({getters, commit}) => {
                const {
                    $apiService,
                    cost,
                    city,
                    currentChunk,
                    totalChunks,
                    apartments,
                    isFetching,
                    price,
                    targetValue
                } = getters
                const canFetch = currentChunk < totalChunks;
                const cityIds = [city]


                if (isFetching || !canFetch) {
                    return
                }


                commit("setFetching", true)

                const {items, page} = await $apiService.fetchApartments({
                    monthFee: cost,
                    cityIds,
                    price,
                    targetValue,
                    page: currentChunk + 1,
                    pageSize: currentChunk > 0 ? 10 : 20
                })

                commit("setIsLastChunk", page === totalChunks)
                commit("setFetching", false)
                commit("setCurrentChunk", page)
                // commit("setTotalChunks", page_count)
                commit("setApartments", [...apartments, ...items])
            },
            resetApartaments: ({commit}) => {
                commit("setCurrentChunk", 0)
                commit("setTotalChunks", 2)
                commit("setApartments", [])
            },
            shareApartment: async ({getters, dispatch}, apartmentId) => {
                const {$apiService, selectedApartment} = getters;
                try {
                    await $apiService.shareApartment(apartmentId);
                    // NEW EVENT
                    dispatch("pushEvent", {event: "SHARE_APARTMENT", data: {apartmentId, url: selectedApartment.link}})
                } catch (error) {
                    console.log(error);
                }
            },

            // Routes
            toForm: ({dispatch}) => {
                dispatch("resetApartaments")
                dispatch("toRoute", VIEW_FORM)
            },
            toFavorites: ({dispatch}) => {
                dispatch("toRoute", VIEW_FAVORITES)
            },
            toMap: ({dispatch}) => dispatch("toRoute", VIEW_MAP),
            toTest: ({dispatch}) => dispatch("toRoute", VIEW_TEST),
            toCatalog: ({dispatch}) => {
                dispatch("selectApartment", 0)
                dispatch("fetchApartments");
                dispatch("toRoute", VIEW_CATALOG);
                dispatch("pushEvent", {event: "TO_CATALOG"});
            },
            toDetail: ({getters, dispatch}, apartment) => {
                if (!apartment) {
                    return
                }

                dispatch("openApartment", apartment)
                dispatch("toRoute", VIEW_DETAIL)
                dispatch("pushEvent", {event: "TO_DETAIL", data: {apartmentId: getters.selectedApartment.id, url: getters.selectedApartment.link}});

                getters.$analyticService.push("view-detail-card", {
                    objectId: getters.openedApartmentId
                })
            },
            toProgram: ({getters, dispatch, commit}, apartment) => {
                if (!apartment) {
                    return
                }

                // commit("setSelectedProgram", null)
                dispatch("openApartment", apartment)
                dispatch("toRoute", VIEW_PROGRAM)
            },
            addSelectedProgram: ({getters, dispatch, commit, state}, program) => {
                commit("setSelectedProgram", program)
            },

            toNext: ({getters, dispatch}) => {
                if (!getters.nextApartment) {
                    return
                }

                dispatch("openApartment", getters.nextApartment)
                dispatch("toRoute", VIEW_DETAIL)
                dispatch("selectApartment", getters.nextIndex)
            },
            toggleTheme: ({getters, commit}) => {
                commit("setTheme", getters.isLightTheme ? THEME_DARK : THEME_LIGHT)
            },
            assignThemeParams({getters}) {
                const root = document.documentElement.style
                const width = window.visualViewport.width
                const height = window.visualViewport.height

                root.setProperty("--viewport-width", `${width}px`)
                root.setProperty("--viewport-height", `${height}px`)

                // getters.$console.log("assignThemeParams", { width, height })
            },
            resetThemeParams() {
                const root = document.documentElement.style

                root.setProperty("--viewport-width", "100%")
                root.setProperty("--viewport-height", "100%")
            },
            initListeners({dispatch}) {
                // window.addEventListener("scroll", event => dispatch("onScroll", event))
                // window.visualViewport.addEventListener("resize", event => dispatch("onResize", event))
            },
            log({getters, commit}, data) {
                commit("setDebugData", [...getters.debugData, data])
            },
            changePrice({commit}, price) {
                commit("setPrice", price)
            },
            changeTargetValue({getters, commit, dispatch}, targetValue) {
                commit("setTargetValue", targetValue)
                dispatch("fetchCostRange")
                dispatch("fetchCities")
                dispatch("pushEvent", {event: "SELECT_APARTMENT_TYPE", data: {type: getters.currentTarget}})
            }
        }
    })
}
