import axios from "axios"

export class ApiService {
	constructor({ baseURL, user }) {
		this.baseURL = baseURL
		this.user = user
		console.log(1)
		console.log(user)
	}

	get userId() {
		return this.user.id
	}


	get client() {
		return axios.create({
			baseURL: this.baseURL,
			responseType: "json",
			headers: {
				"X-Tg-User-Id": this.userId
				// "X-Tg-User-Id": '127621267619301488'
			}
		})
	}

	async pushEvent(event, data) {
		try {			
			await this.client.post("analyst/event/set", {
				category: "WEB_APP",
				event: String(event).toUpperCase(),
				data: data || {}
			})
		} catch ({ message }) {
			throw message
		}
	}

	/**
	 * updateFavorite
	 * @param {*} id
	 */
	async updateFavorite(id, type) {
		try {
			const { data } = await this.client.post("favorite/update", {
				user_id: this.userId,
				entity_id: id,
				entity_type: type
			})

			return data
		} catch {
			throw "Не удалось обновить избранное"
		}
	}

	/**
	 * importFavorite
	 * @param {*} ids
	 */
	async importFavorite(ids = [], type = "apartment") {
		try {
			const { data } = await this.client.get(`favorite/ids?user_id=${this.userId}&entity_type=${type}`)

			return data.items
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * fetchFavorites
	 */
	async fetchFavorites(type = "apartment") {
		try {
			const { data } = await this.client.get(`favorite/list?user_id=${this.userId}&entity_type=${type}`)

			return data
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * Получить список квартир по выбранному городу и ежемесячному платежу
	 * @param {*} param0
	 * @returns
	 */
	async fetchApartments({ cityIds, targetValue, price, page = 1, pageSize = 20 }) {
		const citiesForRequest = cityIds.reduce((accum, item) => (accum += `city_ids[]=${item}&`), "")
		// const monthFeeRequest = `month_fee=${monthFee}`
		const apartmentTypeQuery = `&apartment_type=${targetValue}`
		const min = `min=${price[0]}`
		const max = `max=${price[1]}`
		const pageQuery = `&page=${page}`;
		const pageSizeQuery = `&page_size=${pageSize}`
		try {
			const { data } = await this.client.get("apartment/list?" + citiesForRequest + min + '&' + max + pageQuery + pageSizeQuery + apartmentTypeQuery)

			return data
		} catch {
			throw "Не удалось собрать каталог"
		}
	}

	async shareApartment(apartmentId) {
		try {
			const response = await this.client.get(`apartment/share/${apartmentId}`);

			console.log(response)
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * Получить список городов для выбора
	 */
	async fetchCities(apartmentType) {
		try {
			const apartmentTypeQuery = `?apartment_type=${apartmentType}`

			const { data } = await this.client.get("map/city/list" + apartmentTypeQuery)

			return data
		} catch {
			throw "Не удалось запросить список городов"
		}
	}

	/**
	 * Получить минимальную и максимальную цену по выбранным городам
	 */
	async fetchCostRange(cityIds, apartmentType) {
		const citiesForRequestArr = cityIds.reduce((accum, item) => (accum += `city_ids[]=${item}&`), "")
		const citiesForRequestStr = citiesForRequestArr.slice(0, citiesForRequestArr.length - 1)
		const apartmentTypeQuery = `&apartment_type=${apartmentType}`

		try {
			const { data } = await this.client.get("apartment/price-limits?" + citiesForRequestStr + apartmentTypeQuery)

			return {
				min: parseInt(data.min),
				max: parseInt(data.max)
			}
		} catch {
			throw "Не удалось запросить диапазон цен"
		}
	}
}