const TARGET_FACTOR = 5 
const SOLT_FACTOR = 2
const LEFT_FACTOR = 6 
const RIGHT_FACTOR = 7

const EDGE_LENGTH = 4

/**
 * Encrypt
 * @param {Number} num 
 * @return {String}
 */
export const encrypt = num => {
  num = Math.max(0, num)
   
  const targetNum = num * TARGET_FACTOR
  // const soltNum = targetNum * SOLT_FACTOR

  const leftNum = String(targetNum * LEFT_FACTOR).slice(0, EDGE_LENGTH).padEnd(EDGE_LENGTH, "0")
  const rightNum = String(targetNum * RIGHT_FACTOR).slice(0, EDGE_LENGTH).padEnd(EDGE_LENGTH, "0")

  const targetVector = String(targetNum).split("")
  // const soltVector = String(soltNum).split("")
  const mixedVector = []

  for (let i in targetVector) {
    // mixedVector.push(soltVector[i])
    mixedVector.push(targetVector[i])
  }

  return `${leftNum}` + mixedVector.join("") + `${rightNum}`
} 

/**
 * Decrypt
 * @param {String} num 
 * @return {Number}
 */
export const decrypt = num => {
  const leftNum = num.substring(0, EDGE_LENGTH)
  const rightNum = num.substring(num.length - EDGE_LENGTH)

  num = num.substring(EDGE_LENGTH, num.length - EDGE_LENGTH)

  const targetVector = num.split("")
  const outputVector = []

  for (const i in targetVector) {
    // if (i % 2) {
      outputVector.push(targetVector[i])
    // }
  }

  const targetNum = parseInt(outputVector.join(""))
  const originLeftNum = String(targetNum * LEFT_FACTOR).slice(0, EDGE_LENGTH).padEnd(EDGE_LENGTH, "0")
  const originRightNum = String(targetNum * RIGHT_FACTOR).slice(0, EDGE_LENGTH).padEnd(EDGE_LENGTH, "0")

  if (leftNum !== originLeftNum || rightNum !== originRightNum) {
    throw ""
  }

  return (targetNum / TARGET_FACTOR)
}
